<template>
    <div class="flex flex-col gap-4">
        <div class="flex place-content-between">
            <div class="px-2 pt-2">
                <Logo />
            </div>
            <UTooltip text="Fechar menu">
                <UButton icon="i-heroicons-chevron-double-left-20-solid" color="gray" variant="ghost"
                    class="rounded-none" @click="emit('closeSidebar')"></UButton>
            </UTooltip>
        </div>
        <div v-if="workspaces.length > 1">
            <USelectMenu :loading="loading" v-model="workspace" option-attribute="name" value-attribute="_id"
                :options="workspaces" variant="outline" selectedIcon="null" class="mx-1">

                <template #leading>
                    <UAvatar :src="`https://peoplelabs.blob.core.windows.net/logos/${workspace}.png`" size="2xs"
                        :alt="workspaceName">
                    </UAvatar>
                </template>

                <template #label>
                    <span class="truncate">{{ workspaceName }}</span>
                </template>

                <template #option="{ option: company }">
                    <UIcon v-if="company._id == 0" name="i-heroicons-plus"></UIcon>
                    <UAvatar v-else :src="`https://peoplelabs.blob.core.windows.net/logos/${company._id}.png`"
                        size="2xs" :alt="company.name">
                    </UAvatar>

                    <span class="truncate">{{ company.name }}</span>
                </template>
            </USelectMenu>
        </div>
        <div class="px-1">
            <UVerticalNavigation :links="menu">
                <template #icon="{ link }">
                    <UIcon :name="link.icon" />
                </template>
            </UVerticalNavigation>
        </div>
        <div v-if="!premium && workspaces.length > 1" class="flex justify-center px-3">
            <UButton variant="outline" label="Assine agora" :ui="{ rounded: 'rounded-full' }" to="/plans" block>
            </UButton>
        </div>
    </div>
</template>

<script setup>
const modules = await useModules()
const currentUser = await useCurrentUser()
const router = useRouter()

const { data: workspaces, pending: loading } = await useAsyncData('workspaces', () => $fetch('/api/workspace'))
const { data: premium } = await useAsyncData('premium', () => $fetch('/api/subscription/premium'))

const emit = defineEmits(['closeSidebar'])

workspaces.value.push({
    _id: 0,
    name: 'Criar nova empresa'
})

const workspace = ref(currentUser.company
    ? (workspaces.value.find(x => x._id == currentUser.company) || workspaces.value[0])._id
    : 0)

watch(workspace, async (newWorkspace) => {
    if (newWorkspace && newWorkspace !== '0') {
        try {
            await $fetch('/api/workspace/change', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: {
                    company: newWorkspace
                }
            });

            if (router.currentRoute.value.name !== 'index') {
                await router.push('/');
            } else {
                router.go(0);
            }
        } catch (error) {
            console.error("Erro ao trocar de workspace:", error);
        }
    } else {
        router.push('/company/onboarding');
    }
});

const workspaceName = computed(() => {
    const currentWorkspace = workspaces.value.find(x => x._id === workspace.value);
    return currentWorkspace ? currentWorkspace.name : 'Nome da Empresa';
});


const links = [{
    label: currentUser.displayName,
    to: '/user',
    icon: 'i-heroicons-user-20-solid'
}]

const home = [{
    label: 'Home',
    icon: 'i-heroicons-home-20-solid',
    to: '/'
}]

const newCompany = [{
    label: 'Cadastrar empresa',
    icon: 'i-heroicons-users-20-solid',
    to: '/company/onboarding'
}]

const menu = workspaces.value.length > 1 ? [
    home, modules, links
] : [
    home, newCompany, links
]
</script>